<template>
    <Page
        title="Connect Google"
        icon="mdi-pipe"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        Connect to a
        <span class="secondary--text">google account</span>
        for (eg) gmail functionality.

        <v-layout slot="actions">
            <btn color="success" @click="doConnectGoogleAccount" small>Do it!</btn>
        </v-layout>
    </Page>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showSignUp: true,
            email: '',
            password: '',
            isShowError: false,
            errorMessage: '',
            isShowLoading: false,
            loadingMessage: '',
        };
    },
    methods: {
        ...mapActions(['connectGoogle']),
        async doConnectGoogleAccount() {
            this.loadingMessage = 'Connecting to Google';
            this.isShowLoading = true;
            try {
                const redirectTo = await this.connectGoogle();
                window.location = redirectTo;
            } catch (e) {
                console.log('Error Connecting google ', e);
                this.isShowLoading = false;
            }
        },
    },
    created() {
        if (this.$route.query.errmsg) {
            this.errorMessage = this.$route.query.errmsg;
            this.isShowError = true;
        }
    },
};
</script>
